/*** PFX Styling ***/
@import "../../App.scss";

.instream{
  .thumbs{
    position: relative;

    img{
      width:100%
    }
    video{
      width:95%;
    }

    .play-btn{
      position: absolute;
      z-index:10;
      width: 150px;
      height:40px;
      border-radius:10px;
      border: 1px solid #008000;
      background-color: rgba(255,255,255,0.8);
      color:#008000;
      font-size:1rem;
      text-align: center;
      margin:auto;
      left:0;
      right:0;
      top:0;
      bottom:0;
      opacity:0.8;
      display: none;
    }
  }

  .desc-box{
    ul{
      margin-block-start: 0;
      margin-block-end: 0;
      padding:0 0 0 15px;
      margin:0;
      position:relative;

      li{
        font-size:13px;
        // list-style: none;

        // &::before{
        //   content: '';
        //   display:inline-block;
        //   width: 6px;
        //   height: 6px;
        //   background-color: #B266ED;
        //   border-radius: 50%;
        //   margin-right:10px;
        // }
      }
    }
  }

}

/** Mobile site **/
main.mobile-view{
  
  .ad-filter{
    .title{
      width:100%;
    }

    .demo-btn{
      margin:auto;
      width:50%;
      display: flex;
      justify-content: center;
    }
  }
  .desc-box{
    text-align: left;    
    padding:0 10%;
  }
}