/*** PFX Styling ***/
@import "../../App.scss";

div.content-bottom.no-filter.commerce-ad{
  .title{
    width:100%;
    padding:10px 0;
    margin-bottom:3%;
    min-height:17vh;
    background: linear-gradient(to bottom right, rgb(22, 94, 121), rgb(161, 243, 245));
    color:#fff;

    .hero-img{
      width:70%;
      height:auto;
      margin:auto;
    }
    .text p, .text h2{
      padding:0 20px;
    }
    .text{
      position: relative;
      .comm-filter{
        display:flex;
        position: absolute;
        bottom:10px;
        padding:0 20px;
        font-size:1rem;        
        align-items: center;
        .filter-title{
          font-size:1.3rem;
        }
        .btn-group{
          display:flex;
          button{
            margin:0 15px;          
            font-size:1.2rem;
            background-color:rgba(0,0,0,0.5);
            color:#fff;
            &.btn-active{
              background-color:#52c27a;
            }
          }

        }
       
      }
    }
  }

  .commerce-gallery{
    
    .item-col.display-column{
      height:auto;
      margin-bottom:4rem;
      position:relative;

      .demo-btn{
        position: absolute;
        bottom:-5%;
      }
      &[data-type="social"]{
        display:none;
      }
      video{
        width:70%;
        height:auto;
      }

    }
    .instream-col{
      height:200px;
      
      &[data-type="instream"]{
        display:none;
        video {
          width: 100%;
          height: auto;
        }
      }
      .desc-box{
        position: relative;
        height:140px;

        .demo-btn{
          position: absolute;
          bottom:0;
        }
      }
    }
    
    .card-title{
      margin-block-start:0;
      margin-block-end:0;
    }
  }
   
}

.grid-layout.commerce-gallery {
  padding: 0 1rem;

  video {
    width: auto;
    height: 100%;
  }

  .show-gif {
    position: absolute;
    z-index: 1;
    top: 21.5rem;
    left: 8.5rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 3rem;
    padding: 0.1rem 0.2rem;
    text-align: center;
    cursor: pointer;

    i {
      font-size: 2rem;
      align-self: center;
      color: #04d257;
    }
  }
}

div.active {
  div.show-gif {
    i {
      opacity: 0.5;

      &.fa-pause-circle {
        color: grey;
      }
    }
  }
}


.mobile-view {
  .new .new-label {
    left: 4.4rem;
  }

  div[name="data_container"] {
    text-align: left;
  }

  div.content-bottom.no-filter.commerce-ad{
    .text{
      .comm-filter{
        font-size:1rem;
        margin:3% 0;
        display:block;
        position: relative;
        bottom:0;
        .btn-group{
          button{
            font-size:1rem;
            margin:5px;
          }
        }
      }
     }
    
    .commerce-gallery{
      .desc-box{
        position: relative;
        height:110px;
        margin-top:10px;
        padding:0 10%;
        .demo-btn{
          position: absolute;
          bottom:0;
        }
      }
      .instream-col{
        height:300px;
        video {
          width: 80%;
          height: auto;
        }
      }
    }
  }
  
}