/*** PFX Styling ***/
@import "../../App.scss";

.rmThumb {
  max-width: 300px;
  width: auto !important;
  height: 310px;
  margin: auto;
}

.gallery-box {
  display: flex;
  position: relative;
  margin-bottom: 4rem;
}


.ad-gallery {
  padding: 0 1rem;

  video {
    width: 70%;
    height: auto;
  }

  .show-gif {
    position: absolute;
    z-index: 1;
    top: 21.5rem;
    left: 8.5rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 3rem;
    padding: 0.1rem 0.2rem;
    text-align: center;
    cursor: pointer;

    i {
      font-size: 2rem;
      align-self: center;
      color: #04d257;
    }
  }
}

div.active {
  div.show-gif {
    i {
      opacity: 0.5;

      &.fa-pause-circle {
        color: grey;
      }
    }
  }
}

.mobile-view {
  .new .new-label {
    left: 4.4rem;
  }

  div[name="data_container"] {
    text-align: left;
  }

}