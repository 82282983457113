body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9rem;
  overflow: hidden;
  color: #3c3c3c;
}

button {
  background-color: rgba(0, 0, 0, 0);
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.large-icon {
  font-size: 2rem;
}

.icon-text-size {
  font-size: 1.2rem;
}

.descText {
  font-size: 0.9rem;
  color: #5f5f5f;
}

.text-center {
  text-align: center;
}

h5 {
  font-size: 1rem;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}

.dFlex {
  display: flex;
}

.show {
  display: block;
  opacity: 1;
  transition: all 0.3 ease-in-out;
}

.hide {
  display: none !important;
  ;
  opacity: 0;
}

.showDiv {
  display: flex;
}

.hideDiv {
  display: none !important;
}

.text-lightGrey {
  color: #6c757d;
}


/*** PFX Styling ***/
$nav-button-left: 21px;
$filter-bar-top: 85px;
$sidebar-width: 250px;
$primary-bg-color: #52c27a;

main {
  display: inline-flex;
  width: 100%;
}

div.main.content-right {
  display: flex;
  width: calc(100% - #{$sidebar-width});
  height: 100vh;
}

div.content-bottom {
  padding-top: $filter-bar-top;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100vw - #{$sidebar-width});
  max-height: calc(100vh - #{$filter-bar-top});
}

div.content-bottom.no-filter {
  padding-top: 0;
  max-height: 100%;
}

.btn {
  font-size: 13px;
  border: 0;
  border-radius: 0.3rem;
  padding: 0.8rem 0.8rem;
  color: #ffffff;
  cursor: pointer;
  text-align: left;

  &.btn-primary {
    background-color: #52c27a;
  }

  &.btn-secondary {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #52c27a;
    color: #3c3c3c;
  }
  &.btn-outline-white{
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size:1rem;
  }

  &.btn-inactive {
    background-color: rgba(0, 0, 0, 0);
    text-decoration: underline;
    color: #3c3c3c;
  }
  &.btn-active{
    background-color:#fff;
    color: #52c27a;
  }
}

.new-label {
  display: none;
}

.new .new-label {
  display: block;
  border-radius: 6px;
  width: 50px;
  height: 40px;
  clip-path: polygon(0% 0%,
      100% 0%,
      100% 75%,
      75% 75%,
      75% 100%,
      50% 75%,
      0% 75%);
  background-color: #ff5656;
  position: absolute;
  left: 3rem;
}

.new-label:after {
  content: "NEW";
  color: #fff;
  position: absolute;
  left: 14%;
  top: 10%;
  font-weight: bold;
}


.alert-box {
  padding: 2rem 1rem;
  border: 1px solid #52c27a;
  width: 100%;
  text-align: center;
}

#no-result-alert {
  width: 70vw;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: none;
  text-align: center;
}

.coming-soon {
  width: 100%;
  height: 100%;
  text-align:center;

  h1 {
    display: block;
    font-size: 5rem;
    font-weight: normal;
    color: #52c27a;
  }
}

.coming-lion {
  height: 50%;
  width: 50%;
  margin: auto;
  stroke: #52c27a;
  stroke-width: 8px;
  fill: transparent;
  stroke-dashoffset: 0;
  stroke-dasharray: 3350;
  animation: lion-animation 5s 5;
}

@keyframes lion-animation {
  0% {
    stroke-dashoffset: -3350;
  }

  35% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -3350;
  }
}


// Player
#pfxPlayer {
  width: 100%;
}

// Mobile site

main.mobile-view .main.content-right {
  width: 100%;

  div.content-bottom {
    width: 100%;
    max-height: calc(100% - #{$filter-bar-top});

    &.no-filter {
      padding-top: 50px;
      height: 100%;
    }
    &.commerce-ad
    {padding-top:60px;}
  }
}

main.mobile-view .filter-container {
  width: calc(100% - 40px);
}

div.main.content-right {
  &.expand {
    width: calc(100% - #{$sidebar-width});
  }
}
.scroll-top-btn{
  width:50px;
  height:50px;
  border-radius:40px;
  background-color:rgba(255, 212, 124, 0.5);
  position:fixed;
  bottom:5%;
  right:3%;
  z-index:999999; 
  display:flex; 
  align-items: center;
  justify-content: center;
  display:none;
  i{
    font-size:1.7rem;
    color:#97701f;
  }
}

.demoBox{
  video{
    max-width:300px;
    width:90%;
  }
}