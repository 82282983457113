/*** PFX Styling ***/
@import "../../App.scss";

.ad-filter{
  .thumbs{
    
    video{
      max-width:300px;
      width:90%;
    }
  }

  .title{
    width:60%;
    margin:1rem auto 2rem;
    padding:1.7rem;
    box-shadow: 0px 13px 11px -8px rgba(51,51,0,0.3);
    border-radius:0.5rem;

    .heading {
      font-size:1.3rem;
      color:#008000;
      span {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 2px;
        line-height: 2em;
        padding-left: 0.25em;
        color: rgba(0, 0, 0, 0.4);
        padding-bottom: 10px;
      }
    }
  } 
}

/** Mobile site **/
main.mobile-view{
  .ad-filter{
    .title{
      width:100%;
    }

    .demo-btn{
      margin:auto;
      width:50%;
      display: flex;
      justify-content: center;
    }
  }
}