/*** PFX Styling ***/
@import "../../App.scss";

nav.sidebar { 
    width: 250px;
    height: 100vh;
    background: linear-gradient(135deg, #62c7ed 0%, #1e9a4b 100%);
    padding: 20px 0 0 20px;
    z-index: 10000;
    color: #fff;
    transition: all 0.5s ease-in-out;

    /* Mobile */

  .mobile{
    font-size:1.3rem;
  }
  &.expand{
    margin-left:0;
  }
  &.collapse{
    margin-left:calc(-#{$sidebar-width} - 20px);
  }

    .home-title{
        font-size:1.3rem;
        font-weight:bold;
        border-bottom: 1px solid;
        border-color:rgba(255,255,255,0.5);

        
    }
    .navbar {
      li{
        list-style:none;
      }
    }
  
    #nav-group {
      margin:0;
      padding:0;
  
      li {
        text-align: left;
        border: 0;
        background: rgba(0, 0, 0, 0);
        margin: 0;
        margin-left: -$nav-button-left;
        display: block;
        margin-bottom: 5%;
        color: #fff;
        width: calc(100% + #{$nav-button-left} + 23px);
        min-height: 50px;
        display:flex;
        align-items: center;

        a{
            width:100%;
            height:100%;            
            padding-left:$nav-button-left;
        }
  
        &.active {
          background-color: rgba(255, 255, 255, 0.7);
          color: #1e9a4b;
          transition: all 0.3s ease-in;
        }
      }
    }
    
    .sidebar-content{
      width:90%;
    }

    footer {
      position: absolute;
      bottom: 20px;
      font-size: 9px;
      color: #fff;
    }
  }