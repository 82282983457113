/*** PFX Styling ***/
@import "../../App.scss";

.modal-container{
    position: fixed;
    width:101vw;
    height:101vh;
    background-color: rgba(0,0,0,0.4);
    z-index:100000;
    margin-top:-85px;
    margin-left: calc(-#{$sidebar-width} - 15px);
    transition: all 0.5 ease-in-out;

    .close-modal{
        width:40px;
        height:32px;
        position: absolute;
        z-index:10;
        right: -10px;
        top: -16px;
        font-size:1.5rem;
        border:1px solid gray;
        border-radius:40px;
        color:gray;
        text-align:center;
        padding-top:8px;
        background-color:#ffffff;
        box-shadow: -1px 2px 16px 0px rgba(0,0,0,0.38);
    }


    .modal-body{
        position: absolute;
        max-width: 700px;
        width:calc(65% - 1rem);
        height:calc(70% - 1rem);
        background-color:#fff;
        margin:auto;
        left:0;
        top:0;
        right:0;
        bottom:0;
        border-radius:1rem;
        padding:1rem;      
       
        
        .modal-content {
            .title{
                color:darkslategrey;
                font-size:1.5rem;
                font-weight:bold;
            }

            table{
                border:solid 1px lightgrey;
                text-align:center;

                thead{
                    background-color:$primary-bg-color;
                    color:white;
                    font-weight:bold;
                }
                td, thead th{                    
                    padding:0.3rem;
                }
            }

        }
    }
}

//** Mobile View **//
main.mobile-view{
    .modal-container{
        width:100%;
        height:100%;
        margin-left:0;

        .close-modal{
            right: 5px;
            top: 5px;
        }

        .modal-body{
            width:80%;
            max-height:70%;
            overflow-x: hidden;
            overflow-y: auto;

            .demoBox{
                margin-top:3rem;
            }
        }
    }
}